<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/raceManagement/team_management/team_race_management' }">团体赛赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/raceManagement/team_management/team_club_management' }">俱乐部管理</el-breadcrumb-item>
      <el-breadcrumb-item>俱乐部详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
        <el-tab-pane label="俱乐部信息" name="clubInfo">
          <el-row class="title_row">
            <el-col :span="12">
              <span>俱乐部信息</span>
            </el-col>
          </el-row>
          <el-card style="margin-top: 15px">
            <el-form
              label-width="130px"
              :model="clubInfoForm"
              ref="clubInfoFormRef"
              :rules="clubInfoFormRules"
            >
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="学校名称" prop="schoolName">
                    <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="clubInfoForm.schoolName"
                    >
                      <el-option
                        v-for="item in schoolDataList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="学校英文名称" prop="schoolEName">
                    <el-input
                      v-model="clubInfoForm.schoolEName"
                      placeholder="请输入"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8">
                  <el-form-item label="学校地区" prop="schoolAreaCode">
                    <el-cascader
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="clubInfoForm.schoolAreaCode"
                      :options="areasData"
                      :props="{
                        expandTrigger: 'hover',
                        label: 'name',
                        value: 'areaId',
                        children: 'areas',
                      }"
                      disabled
                    ></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="学校创建时间" prop="schoolCreateTime">
                    <el-input
                      v-model="clubInfoForm.schoolCreateTime"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8">
                  <el-form-item label="学校类型" prop="schoolType">
                    <el-select
                      style="width: 100%"
                      v-model="clubInfoForm.schoolType"
                      disabled
                    >
                      <el-option
                        v-for="item in dict_schoolType"
                        :key="item.dictCode"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="学校详细地址" prop="clubAddress">
                    <el-input
                      type="textarea"
                      v-model="clubInfoForm.clubAddress"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="学校办学理念" prop="clubPhilosophy">
                    <el-input
                      v-model="clubInfoForm.clubPhilosophy"
                      placeholder="请输入"
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="学校简介" prop="clubProfile">
                    <el-input
                      v-model="clubInfoForm.clubProfile"
                      placeholder="请输入"
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="俱乐部名称" prop="clubName">
                    <el-input
                      placeholder="请输入"
                      v-model="clubInfoForm.clubName"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="俱乐部组别" prop="clubGroup">
                    <el-select
                      style="width: 100%"
                      v-model="clubInfoForm.clubGroup"
                      disabled
                    >
                      <el-option
                        v-for="item in dict_itsoGroup"
                        :key="item.dictCode"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="俱乐部口号" prop="clubSlogan">
                    <el-input
                      placeholder="请输入"
                      v-model="clubInfoForm.clubSlogan"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item label="俱乐部理念" prop="clubPhilosophy">
                    <el-input
                      placeholder="请输入"
                      v-model="clubInfoForm.clubPhilosophy"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col>
                  <el-form-item label="是否参与过SPBCN" prop="isAddSpbcn">
                    <el-switch
                      v-model="clubInfoForm.isAddSpbcn"
                      active-value="true"
                      inactive-value="false"
                    >
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    v-if="clubInfoForm.isAddSpbcn === 'true'"
                    label="SPBCN参赛经历"
                    prop="addSpbcnInfo"
                  >
                    <el-input
                      v-model="clubInfoForm.addSpbcnInfo"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="是否参与过其他国际赛事"
                    prop="isaddnationmatch"
                  >
                    <el-switch
                      v-model="clubInfoForm.isAddNationMatch"
                      active-value="true"
                      inactive-value="false"
                    >
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    v-if="clubInfoForm.isAddNationMatch === 'true'"
                    label="国际赛事参赛经历"
                    prop="addNationMatchInfo"
                  >
                    <el-input
                      v-model="clubInfoForm.addNationMatchInfo"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="学校图片" prop="schoolPicurl">
                    <el-upload
                      class="avatar-uploader"
                      :action="upLoadPicUrl"
                      :show-file-list="false"
                      :on-success="uploadSchoolPicSuccess"
                      :before-upload="beforeUpload"
                      :data="{
                        filePath: 'teamMatch/schoolPic/',
                      }"
                    >
                      <img
                        class="avatar-school"
                        :src="clubInfoForm.schoolPicurl"
                      />
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="俱乐部Logo" prop="clubLogoUrl">
                    <el-upload
                      class="avatar-uploader"
                      :action="upLoadPicUrl"
                      :show-file-list="false"
                      :on-success="uploadClubLogoSuccess"
                      :before-upload="beforeUpload"
                      :data="{
                        filePath: 'teamMatch/clubLogo/',
                      }"
                    >
                      <img
                        class="avatar-club"
                        :src="clubInfoForm.clubLogoUrl"
                      />
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <div style="margin-top: 15px">指导教师信息</div>
          <el-card style="margin-top: 15px">
            <el-form
              label-width="130px"
              :model="teacherInfoForm"
              ref="teacherInfoFormRef"
              :rules="teacherInfoFormRules"
            >
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="姓名" prop="userName">
                    <el-input
                      placeholder="请输入"
                      v-model="teacherInfoForm.userName"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="电话" prop="phone">
                    <el-input
                      placeholder="请输入"
                      v-model="teacherInfoForm.phone"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-row type="flex" justify="center" style="margin-top: 20px">
            <el-col :span="4">
              <el-button
                type="primary"
                style="width: 100%"
                @click="saveClubInfo"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="战队信息" name="teamInfo">
          <el-card
            v-for="(item, i) in teamInfoList"
            :key="i"
            style="margin-top: 15px"
          >
            <el-row>
              <el-col :span="6">
                {{ item.scheduleName }}
              </el-col>
              <el-col :span="6">
                组队资格:
                <el-switch
                  v-model="item.isHaveTeam"
                  @change="changeCanTeamUp(item.raceScheduleId, item.isHaveTeam)"
                  :active-value="true"
                  :inactive-value="false"
                ></el-switch>
              </el-col>
              <el-col :span="3">
                <el-button
                  v-if="item.isHaveTeam === true"
                  type="primary"
                  size="mini"
                  @click="showAddTeamDialog(item)"
                  >创建队伍</el-button
                >
              </el-col>
              <!-- <el-col :span="3">
                <el-button
                  v-if="item.canTeamUp === 'true'"
                  type="primary"
                  size="mini"
                  @click="showAddTeacherDialog(item)"
                  >添加带队老师</el-button
                >
              </el-col> -->
            </el-row>
            <el-row>
              <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="item.itsoTeams"
                border
                stripe
              >
                <el-table-column prop="name" label="战队名称">
                </el-table-column>
                <el-table-column
                  prop="leadTeacherUser"
                  label="领队老师"
                >
                <template slot-scope="scope">
                  <span>{{scope.row.leadTeacherUser.userName}}: {{scope.row.leadTeacherUser.phone}}</span>
                </template>
                </el-table-column>
                <el-table-column
                  prop="coachTeacher"
                  label="教练"
                >
                <template slot-scope="scope">
                  <span>{{scope.row.coachTeacher ? scope.row.coachTeacher : '未设置'}}</span>
                </template>
                </el-table-column>
                <el-table-column prop="teamUsers" label="战队成员" width="280px">
                  <template slot-scope="scope">
                    <div
                      v-for="(childItem, index) in scope.row.teamUsers"
                      v-bind:key="index"
                    >
                      <el-row>
                        <el-col :span="5">{{ childItem.sysUser.userName }}</el-col>
                        <el-col :span="9">{{ childItem.sysUser.phone }}</el-col>
                        <el-col :span="5"
                          ><span
                            style="color: green"
                            v-if="childItem.payd === true"
                            >已缴费</span
                          ></el-col
                        >
                        <el-col :span="5" v-if="childItem.payd === true">
                          <el-link
                            type="primary"
                            @click="paymentVoucherClicked(childItem)"
                            >缴费凭证</el-link
                          >
                        </el-col>
                        <el-col :span="5"
                          ><span
                            style="color: red"
                            v-if="childItem.payd === false"
                            >未缴费</span
                          ></el-col
                        >
                        <el-col :span="5" v-if="childItem.payd === false">
                          <el-link
                            type="primary"
                            @click="confirmationofPayment(childItem)"
                            >确认缴费</el-link
                          >
                        </el-col>
                      </el-row>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template scope="scope">
                    <el-button
                      type="text"
                      icon="el-icon-edit"
                      size="small"
                      @click="showEditTeamDialog(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      icon="el-icon-delete"
                      size="small"
                      @click="showDeleteTeamDiaTip(scope.row.id)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="领队老师" name="teacherInfo">
          <el-row class="title_row">
            <el-col :span="12">
              <span>领队老师列表</span>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button type="primary" @click="showAddTeacherDialog()"
                >添加领队老师</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="teacherInfoList"
            header-cell-class-name="tableHeaderStyle"
            border
            stripe
          >
            <el-table-column label="姓名" prop="name">
              <template scope="scope">
                <span v-if="scope.row.sysUser.userName">{{ scope.row.sysUser.userName }}</span>
                <span v-else>未完善信息</span>
              </template>
            </el-table-column>
            <el-table-column label="账号" prop="phone">
              <template scope="scope">
                <span v-if="scope.row.sysUser.phone">{{ scope.row.sysUser.phone }}</span>
                <span v-else>未完善信息</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="成员信息" name="memberInfo">
          <el-row class="title_row">
            <el-col :span="12">
              <span>俱乐部成员列表</span>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button
                type="primary"
                @click="studentPhoneDialogVisible = true"
                >新增俱乐部成员</el-button
              >
              <el-button type="primary" @click="showJoinMemberListDialog()"
                >管理申请加入名单</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="memberInfoList"
            header-cell-class-name="tableHeaderStyle"
            border
            stripe
          >
            <el-table-column label="姓名" prop="userName">
              <template slot-scope="scope">
            <span>{{scope.row.sysUser.userName}}</span>
          </template>
            </el-table-column>
            <el-table-column label="账号" prop="phone">
              <template slot-scope="scope">
            <span>{{scope.row.sysUser.phone}}</span>
          </template>
            </el-table-column>
            <el-table-column label="年级" prop="grade">
              <template slot-scope="scope">
            <span>{{scope.row.sysUserInfo.grade}}</span>
          </template>
            </el-table-column>
            <el-table-column label="班级" prop="classes">
              <template slot-scope="scope">
            <span>{{scope.row.sysUserInfo.classes}}</span>
          </template>
            </el-table-column>
            <el-table-column label="全国半缴费" prop="halfEnrollment">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.halfEnrollment && scope.row.halfEnrollment.openType > 0" size="mini"
                  >已缴费</el-tag
                >
                <el-tag type="danger" v-else size="mini"
                  >未缴费</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="全国总缴费" prop="openType">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.finalEnrollment && scope.row.finalEnrollment.openType > 0" size="mini"
                  >已缴费</el-tag
                >
                <el-tag type="danger" v-else size="mini"
                  >未缴费</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                  @click="showRemoveMemberDiaTip(scope.row.id)"
                  >移除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- 编辑战队的Dialog -->
    <el-dialog
      :title="teamType === 'edit' ? '编辑战队' : '新建战队'"
      :visible.sync="editTeamDialogVisable"
      width="50%"
      @close="editTeamDialogClose"
    >
      <el-form
        label-width="120px"
        :model="editTeamForm"
        ref="editTeamFormRef"
        :rules="editTeamFormRules"
      >
        <el-form-item label="战队名称" prop="name">
          <el-input placeholder="请输入" v-model="editTeamForm.name"></el-input>
        </el-form-item>
        <el-form-item label="领队账号" prop="leadTeacherUserId">
          <el-select style="width: 100%" v-model="editTeamForm.leadTeacherUserId">
            <el-option
              v-for="item in teacherInfoList"
              :key="item.userId"
              :label="(item.sysUser.userName ? item.sysUser.userName : '未完善信息') + '+' + item.sysUser.phone"
              :value="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教练信息" prop="coachTeacher">
          <el-input
            placeholder="姓名+手机号"
            v-model="editTeamForm.coachTeacher"
          ></el-input>
        </el-form-item>
        <el-form-item label="战队成员" prop="teamUserIds">
          <el-select
            v-model="editTeamForm.teamUserIds"
            filterable
            placeholder="请选择"
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="item in memberInfoList"
              :key="item.userId"
              :label="item.sysUser.userName + item.sysUser.phone"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editTeamDialogVisable = false">取 消</el-button>
        <el-button type="primary" @click="editTeam">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 管理申请加入列表的Dialog -->
    <el-dialog
      title="申请加入俱乐部成员列表"
      :visible.sync="joinMemberListDialogVisable"
      width="50%"
    >
      <el-table
        :data="joinMemberInfoList"
        ref="joinMemberInfoListTableRef"
        @selection-change="joinMemberInfoLisTabletSelectionChange"
        header-cell-class-name="tableHeaderStyle"
        border
        stripe
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="姓名" prop="userName">
          <template slot-scope="scope">
            <span>{{scope.row.sysUser.userName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="账号" prop="phone">
          <template slot-scope="scope">
            <span>{{scope.row.sysUser.phone}}</span>
          </template>
        </el-table-column>
        <el-table-column label="年级" prop="grade">
          <template slot-scope="scope">
            <span>{{scope.row.sysUserInfo.grade}}</span>
          </template>
        </el-table-column>
        <el-table-column label="班级" prop="classes">
          <template slot-scope="scope">
            <span>{{scope.row.sysUserInfo.classes}}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="joinMemberListDialogVisable = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="joinMember">同意加入</el-button>
      </span>
    </el-dialog>
    <!-- 缴费凭证弹窗 -->
    <el-dialog
      title="缴费凭证"
      :visible.sync="showPaymentVoucherDialogVisable"
      width="50%"
    >
      <el-image :src="paymentVoucherUrl" fit="fit"></el-image>
    </el-dialog>
    <!-- 显示缴费弹窗 -->
    <el-dialog
      title="确认缴费"
      :visible.sync="confirmationofPaymentDialogVisible"
      @close="confirmationofDialogClose"
      width="40%"
    >
      <el-form
        :model="confirForm"
        :rules="confirFormRules"
        ref="confirFormRef"
        label-width="80px"
      >
        <el-form-item label="缴费凭证" prop="payImageUrl">
          <el-upload
            :class="[hideUploadAdd == true ? 'hide' : '']"
            ref="uploadCoverImgRef"
            :data="{
              filePath: 'teamMatch/PaymentPic/',
            }"
            :action="upLoadPicUrl"
            :limit="1"
            list-type="picture-card"
            style="margin-top: 10px"
            accept=".jpg,.jpeg,.png,.bmp"
            :file-list="imgFilesList"
            :multiple="true"
            :headers="headerObj"
            :on-success="uploadPicSuccess"
            :before-upload="beforeAvatarUpload"
            :on-error="uploadPicError"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="el-upload__tip">jpg、jpeg、png格式，大小5M以内</div>
        </el-form-item>
        <el-form-item label="支付备注" prop="payNote">
          <el-input v-model="confirForm.payNote"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="confirmationofPaymentDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="setPaystateData()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 添加带队老师账号 -->
    <el-dialog
      title="带队老师账号"
      :visible.sync="teacherPhoneDialogVisible"
      @close="teacherPhoneDialogClose"
      width="40%"
    >
      <el-form
        :model="teacherPhoneForm"
        :rules="teacherPhoneFormRules"
        ref="teacherPhoneFormRef"
        label-width="100px"
      >
        <el-form-item label="教师手机号" prop="phone">
          <el-input v-model="teacherPhoneForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="teacherPhoneDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTeacherPhoneData()"
          >添 加</el-button
        >
      </div>
    </el-dialog>
    <!-- 添加俱乐部学生账号 -->
    <el-dialog
      title="学生账号"
      :visible.sync="studentPhoneDialogVisible"
      @close="studentPhoneDialogClose"
      width="40%"
    >
      <el-form
        :model="studentPhoneForm"
        :rules="studentPhoneFormRules"
        ref="studentPhoneFormRef"
        label-width="100px"
      >
        <el-form-item label="学生手机号" prop="phone">
          <el-input v-model="studentPhoneForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="studentPhoneDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addStudentPhoneData()"
          >添 加</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllAreaList, getClubInfoById, openTeamStatus, editTeamData, updateTeamPayd, getNoTeamClubUserList, itsoteamDelete, createTeamData, editErpClubInfo, getListByClubId, getClubUserList, addTeamTeacher, addClubUser, clubUpdateState, deleteClubUser, getClubUserTeacherList } from '@/http/api'

export default {
  data () {
    return {
      teamType: '',
      studentPhoneDialogVisible: false,
      teacherInfoList: [],
      teacherPhoneDialogVisible: false,
      teacherPhoneForm: {
        phone: ''
      },
      studentPhoneForm: {
        phone: ''
      },
      studentPhoneFormRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      teacherPhoneFormRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      hideUploadAdd: false,
      imgFilesList: [],
      headerObj: {
        Authorization: localStorage.getItem('Authorization')
      },
      confirForm: {
        payNote: '',
        payImageUrl: ''
      },
      confirFormRules: {
        payImageUrl: [{ required: true, message: '请选择', trigger: 'change' }],
        payNote: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dialogVisible: false,
      dialogImageUrl: '',
      confirmationofPaymentDialogVisible: false,
      paymentVoucherUrl: '',
      showPaymentVoucherDialogVisable: false,
      clubId: this.$route.query.clubId,
      areasData: [],
      activeName: 'clubInfo',
      clubInfoForm: {
        clubName: '',
        clubSlogan: '',
        clubPhilosophy: '',
        clubGroup: '',
        schoolArea: [],
        schoolId: '',
        schoolEName: '',
        schoolCreateTime: '',
        schoolPhilosophy: '',
        schoolType: '',
        schoolAddress: '',
        schoolProfile: '',
        isAddspbcn: '0',
        addSpbcnInfo: '',
        isAddNationMatch: '0',
        addNationMatchInfo: '',
        schoolPicUrl: '',
        clubLogoUrl: ''
      },
      clubInfoFormRules: {
        clubName: [{ required: true, message: '请输入', trigger: 'blur' }],
        clubSlogan: [{ required: true, message: '请输入', trigger: 'blur' }],
        clubPhilosophy: [{ required: true, message: '请输入', trigger: 'blur' }],
        clubGroup: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      schoolDataList: [],
      dict_itsoGroup: this.$userInfo.dict_schoolGroup(),
      dict_schoolType: this.$userInfo.getDataList(this, 'school_type').then(value => { this.dict_schoolType = value }),
      dict_gender: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.e_sex = value }),
      upLoadPicUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
      teacherInfoForm: {
        userName: ''
      },
      teacherInfoFormRules: {
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      teamInfoList: [],
      memberInfoList: [],
      joinMemberListDialogVisable: false,
      joinMemberInfoList: [],
      editTeamDialogVisable: false,
      editTeamForm: {
        name: '',
        leadTeacherUserId: '',
        coachTeacher: '',
        teamUserIds: []
      },
      editTeamFormRules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        leadTeacherUserId: [{ required: true, message: '请输入', trigger: 'blur' }],
        teamUserIds: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      joinMemberList: []
    }
  },
  created () {
    this.getAllAreaList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
        this.getClubInfo()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    tabClick (tab, event) {
      switch (tab.name) {
        case 'clubInfo':
          this.getClubInfo()
          break
        case 'teacherInfo':
          this.getTeacherInfo()
          break
        case 'teamInfo':
          this.getTeamInfo()
          this.getTeacherInfo()
          break
        case 'memberInfo':
          this.getMemberInfo()
          break
      }
    },
    // 获取俱乐部信息
    getClubInfo () {
      console.log('this.clubId', this.clubId)
      getClubInfoById({
        id: this.clubId
      }).then((res) => {
        if (res.code === 200) {
          this.clubInfoForm = res.data.clubInfo
          this.clubInfoForm.schoolType = this.clubInfoForm.schoolType + ''
          if (res.data.teacherInfo) {
            this.teacherInfoForm = res.data.teacherInfo
          }
        }
      })
    },
    // 获取战队信息
    getTeamInfo () {
      getListByClubId({
        clubId: this.clubId
      }).then((res) => {
        if (res.code === 200) {
          this.teamInfoList = res.data.map(item => {
            if (item.itsoClubTeam && item.itsoClubTeam.openStatus === true) {
              item.isHaveTeam = true
            } else {
              item.isHaveTeam = false
            }
            return item
          })
        }
      })
    },
    // 获取已加入俱乐部成员信息
    getMemberInfo () {
      getClubUserList({
        clubId: this.$route.query.clubId,
        state: 1
      }).then((res) => {
        if (res.code === 200) {
          this.memberInfoList = res.data
        }
      })
    },
    // 获取申请俱乐部成员列表
    showJoinMemberListDialog () {
      getClubUserList({ clubId: this.clubId, state: 0 }).then((res) => {
        this.joinMemberInfoList = res.data
        this.joinMemberListDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取领队老师列表
    getTeacherInfo () {
      getClubUserTeacherList({ clubId: this.clubId, state: 1 }).then((res) => {
        this.teacherInfoList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    uploadSchoolPicSuccess (res, file) {
      this.clubInfoForm.schoolPicUrl = res.data.url
    },
    uploadClubLogoSuccess (res, file) {
      this.clubInfoForm.clubLogoUrl = res.data.url
    },
    beforeUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 保存俱乐部信息
    saveClubInfo () {
      this.$refs.clubInfoFormRef.validate(async valid => {
        if (!valid) return
        editErpClubInfo(this.clubInfoForm).then((res) => {
          this.$message.success('保存成功')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 新建站队
    showAddTeamDialog (item) {
      console.log('item', item)
      var formData = {
        clubId: this.clubId,
        raceSchedule: item.raceScheduleId,
        itsoTeamId: item.id
      }
      this.editTeamForm = {
        raceSchedule: item.raceScheduleId,
        clubId: this.clubId,
        teamUserIds: []
      }
      this.teamType = 'created'
      this.getNoTeamMemberInfo(formData)
    },
    // 编辑战队
    showEditTeamDialog (teamData) {
      console.log('teamData', teamData)
      var formData = {
        clubId: teamData.clubId,
        raceSchedule: teamData.raceSchedule,
        itsoTeamId: teamData.id
      }
      var selectUserIds = []
      for (let index = 0; index < teamData.teamUsers.length; index++) {
        const element = teamData.teamUsers[index]
        selectUserIds.push(element.sysUser.id)
      }
      this.editTeamForm.coachTeacher = teamData.coachTeacher
      this.editTeamForm.itsoTeamId = teamData.id
      this.editTeamForm.leadTeacherUserId = teamData.leadTeacherUserId
      this.editTeamForm.name = teamData.name
      this.editTeamForm.teamUserIds = selectUserIds
      this.teamType = 'edit'
      this.getNoTeamMemberInfo(formData)
    },
    // 获取未加入其他战队的俱乐部成员
    getNoTeamMemberInfo (formData) {
      console.log('formData', formData)
      getNoTeamClubUserList(formData).then((res) => {
        if (res.code === 200) {
          this.memberInfoList = res.data
          this.editTeamDialogVisable = true
        }
      })
    },
    // 修改战队信息
    editTeam () {
      console.log('this.editTeamForm', this.editTeamForm)
      this.$refs.editTeamFormRef.validate(async valid => {
        if (!valid) return
        if (this.editTeamForm.teamUserIds.length < 5 || this.editTeamForm.teamUserIds.length > 7) {
          return this.$message.warning('战队人数需要5-7人')
        }
        if (this.teamType === 'edit') {
          editTeamData(this.editTeamForm).then((res) => {
            this.$message.success('保存成功')
            this.editTeamDialogVisable = false
            this.getTeamInfo()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        } else {
          createTeamData(this.editTeamForm).then((res) => {
            this.$message.success('创建成功')
            this.editTeamDialogVisable = false
            this.getTeamInfo()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }
      })
    },
    // 删除战队点击事件
    showDeleteTeamDiaTip (teamId) {
      this.$confirm('此操作将永久删除该队伍, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        itsoteamDelete({ id: teamId }).then((res) => {
          this.$message.success('删除成功')
          this.getTeamInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => { })
    },
    // 移除俱乐部成员
    showRemoveMemberDiaTip (memberId) {
      this.$confirm('此操作将移除该成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteClubUser({ ids: [memberId] }).then((res) => {
          this.$message.success('移除成功')
          this.getMemberInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => { })
    },
    showAddTeacherDialog (item) {
      console.log('item', item)
      this.teacherPhoneDialogVisible = true
    },
    teacherPhoneDialogClose () {
      this.$refs.teacherPhoneFormRef.resetFields()
    },
    editTeamDialogClose () {
      this.$refs.editTeamFormRef.resetFields()
    },
    joinMemberInfoLisTabletSelectionChange (data) {
      var ids = []
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          ids.push(element.id)
        }
      }
      this.joinMemberList = ids
    },
    joinMember () {
      if (this.joinMemberList.length === 0) {
        return this.$message.warning('请至少选择一个选手')
      }
      clubUpdateState({ state: 1, ids: this.joinMemberList }).then((res) => {
        this.$message.success('操作成功')
        this.joinMemberListDialogVisable = false
        this.getMemberInfo()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    async changeCanTeamUp (matchId, canTeamUp) {
      openTeamStatus({
        clubId: this.clubId,
        raceSchedule: matchId,
        openStatus: canTeamUp
      }).then((res) => {
        this.$message.success('操作成功')
        this.getTeamInfo()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 确认添加手机号
    addTeacherPhoneData () {
      addTeamTeacher({ phone: this.teacherPhoneForm.phone, clubId: this.clubId }).then((res) => {
        this.teacherPhoneDialogVisible = false
        this.$message.success('添加成功')
        this.getTeacherInfo()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 预览缴费凭证
    paymentVoucherClicked (childItem) {
      if (childItem.payImageUrl) {
        this.paymentVoucherUrl = childItem.payImageUrl
        this.showPaymentVoucherDialogVisable = true
      } else {
        this.$message.warning('线上缴费，无缴费凭证')
      }
    },
    // 确认缴费点击
    confirmationofPayment (rowData) {
      console.log('rowData', rowData)
      this.confirForm.id = rowData.id
      this.confirForm.payd = true
      this.confirmationofPaymentDialogVisible = true
      this.getTeamInfo()
    },
    // 缴费弹窗关闭
    confirmationofDialogClose () {
      this.$set(this.confirForm, 'payImageUrl', null)
      this.imgFilesList = []
      this.hideUploadAdd = false
      this.$refs.confirFormRef.resetFields()
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 限制图片大小
    beforeAvatarUpload (file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt5M
    },
    uploadPicSuccess (res) {
      console.log('uploadPicSuccess', res)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      if (res.data) {
        this.$set(this.confirForm, 'payImageUrl', res.data)
        this.hideUploadAdd = true
        this.$message.success('上传图片成功')
      }
    },
    uploadPicError (error) {
      console.log('uploadPicError', error)
    },
    // 确认修改支付状态
    setPaystateData () {
      if (this.$refs.uploadCoverImgRef.uploadFiles.length === 0) {
        return this.$message.warning('请先选择缴费凭证！')
      }
      this.$refs.confirFormRef.validate(valid => {
        if (!valid) return
        this.setUpPaySatet()
      })
    },
    // 修改支付状态请求
    async setUpPaySatet () {
      updateTeamPayd(this.confirForm).then((res) => {
        this.confirmationofPaymentDialogVisible = false
        this.getTeamInfo()
        return this.$message.success('修改成功！')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    async addStudentPhoneData () {
      this.studentPhoneForm.clubId = this.clubId
      addClubUser(this.studentPhoneForm).then((res) => {
        this.studentPhoneDialogVisible = false
        this.getMemberInfo()
        return this.$message.success('添加成功！')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    studentPhoneDialogClose () {
      this.$refs.studentPhoneFormRef.resetFields()
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-school {
  width: 300px;
  display: block;
}

.avatar-club {
  width: 150px;
  display: block;
}
</style>
